<script lang="ts" setup>

const userHasConsented = ref(true);
const error = ref(false);

const setAgeConsent = () => {
    document.cookie = "ageConsent=true";
    userHasConsented.value = true;
};
const checkAgeConsent = () => {
    // get the cookie "ageConsent"
    const ageConsent = document.cookie
        .split(";")
        .find((cookie) => cookie.includes("ageConsent"));
    // if the cookie "ageConsent" is true
    if (!ageConsent) {
        // set the variable userHasConsented to true
        userHasConsented.value = false;
    }
};

const setError = () => {
    error.value = true;
};

const close = () => {
    // set the variable userHasConsented to true
    setAgeConsent();
};

// checkAgeConsent onMounted
onMounted(() => {
    checkAgeConsent();
});

</script>

<template>
<div
    v-if="!userHasConsented"
    class="fixed z-50 inset-0 overflow-y-auto"
    role="dialog"
    aria-modal="true"
>
    <div class="flex items-start sm:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-black bg-opacity-24 transition-opacity" aria-hidden="true"></div>
        <div class="relative inline-block align-bottom text-center pb-12 sm:mt-60 sm:align-middle max-w-xs sm:max-w-lg w-10/12 sm:w-full">
            <div class="absolute z-1">
                <NuxtImg src="/hvid_stor_splat.png" width="650" class="hidden sm:block splat-img" />
                <NuxtImg src="/hvid_halv_splat.png" width="500" class="sm:hidden splat-halv-img" />
            </div>
            <div class="relative z-5">
                <p class="text-2xl xs:mb-0 sm:mt-0 sm:text-4xl mx-2 font-bold">{{ $t('ageConsent.title') }}</p>
                <p class="text-gray-400">{{ $t('ageConsent.body') }}</p>
                <div class="flex justify-center">
                    <a
                        data-test-id="age-consent-no"
                        class="btn cursor-pointer text-white bg-brand-red inline-block text-center m-2 sm:m-4 p-4 text-xl sm:text-2xl font-bold focus:outline-none disabled:opacity-75 min-w-[100px]"
                        @click="setError"
                    >
                        {{ $t('ageConsent.btnNo') }}
                    </a>
                    <a
                        data-test-id="age-consent-yes"
                        class="btn cursor-pointer text-white bg-brand-darkGreen inline-block text-center m-2 sm:m-4 p-4 text-xl sm:text-2xl font-bold focus:outline-none disabled:opacity-75 min-w-[100px]"
                        @click="close"
                    >
                        {{ $t('ageConsent.btnYes') }}
                    </a>
                </div>
                <div data-test-id="age-consent-error" class="text-xs max-w-[150px] sm:max-w-unset mx-auto text-red-500" v-if="error">
                    {{ $t('ageConsent.error') }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.splat-img {
    transform: translate(-11%,-27%);
}
.splat-halv-img {
    transform: translate(-23%, -5%);
}
@media screen and (min-width: 375px) {
    .splat-halv-img {
        transform: translate(-18%, -5%);
    }
}
</style>
