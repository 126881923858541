import {ref} from 'vue';
import {useConsentStore} from '~/stores/consentStore';

export function useConsent() {
    const consentStore = useConsentStore();

    const checkConsent = () => {
        if (process.client && window.CookieInformation) {
            consentStore.setStatisticConsent(window.CookieInformation.getConsentGivenFor('cookie_cat_statistic'));
            consentStore.setMarketingConsent(window.CookieInformation.getConsentGivenFor('cookie_cat_marketing'));
            consentStore.setFunctionalConsent(window.CookieInformation.getConsentGivenFor('cookie_cat_functional'));
        }
    };

    const initConsentListener = () => {
        if (process.client) {
            window.addEventListener('CookieInformationConsentGiven', (event) => {
                checkConsent();
            });
            // Initial check
            checkConsent();
        }
    };

    return {
        initConsentListener,
        hasStatisticConsent: consentStore.hasStatisticConsent,
        hasMarketingConsent: consentStore.hasMarketingConsent,
        hasFunctionalConsent: consentStore.hasFunctionalConsent,
    };
}
