import aa from 'search-insights';
import {Customer, Order, OrderLineItem, SessionContext} from "@shopware-pwa/types";
import {InitParams} from "search-insights/lib/init";
import {TrackingProduct} from "~/types/TrackingProduct";
import {useNuxtApp} from "#imports";

export default function useAlgolia() {
    const algoliaConfig = useRuntimeConfig().public.algoliaDev;

    const getAlgoliaConfig = function () {
        return algoliaConfig;
    }

    /**
     * Used in the ais-instant-search component
     */
    const insightsSettings = function (): object {
        return {
            insightsClient: insightsClient
        }
    };

    const algoliaSetAuthenticatedToken = function (customer: Customer | null, token: string | null = null): void {
        if (customer && token === null) {
            token = customer.id;
        }

        if (token) {
            insightsClient('setAuthenticatedUserToken', token);
        }

    };

    const algoliaTrackPurchase = function (order: Order): void {
        const allObjectIds = order.lineItems?.map((lineItem: OrderLineItem) => lineItem.productId);
        for (let i = 0; i < allObjectIds.length; i += 20) {
            const objectIds = allObjectIds.slice(i, i + 20);

            const objectData = order.lineItems?.filter(
                (lineItem: OrderLineItem) => objectIds.includes(lineItem.productId)
            ).map(
                (lineItem: OrderLineItem) => {
                    return {
                        price: lineItem.unitPrice,
                        quantity: lineItem.quantity,
                        queryID: getSearchHistoryForId(lineItem.productId)
                    }
                }
            );
            insightsClient('purchasedObjectIDsAfterSearch', {
                eventName: 'Purchase',
                index: getProductIndexName(),
                currency: order.currency?.isoCode ?? 'DKK',
                objectIDs: objectIds,
                objectData: objectData,
            });
        }
    }

    const algoliaTrackAddToCart = function (sessionContext: SessionContext | undefined, products: TrackingProduct[], title: string, queryId?: string): void {
        if (!queryId && products.length === 1) {
            queryId = getSearchHistoryForId(products[0].id);
        }
        const currency = sessionContext?.currency?.isoCode ?? 'DKK'; // hashtag-made-in-denmark
        insightsClient(queryId ? 'addedToCartObjectIDsAfterSearch' : 'addedToCartObjectIDs', {
            eventName: title,
            index: getProductIndexName(),
            queryID: queryId,
            objectIDs: products.map((product: TrackingProduct) => product.id),
            objectData: products.map((product: TrackingProduct) => {
                return {
                    price: product.activePrice,
                    quantity: product.quantity
                }
            }),
            currency: currency
        })
    }

    const algoliaTrackClick = function (productId: string, title: string, queryId?: string, position?: number): void {
        if (!queryId) {
            queryId = getSearchHistoryForId(productId);
        }

        insightsClient(queryId ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs', {
            eventName: title,
            index: getProductIndexName(),
            queryID: queryId,
            positions: queryId ? [(position ?? 1)] : undefined,
            objectIDs: [productId],
        })
    }

    const insightsClient = function (...args: any[]): any {
        return aa(...args);
    }

    const getProductIndexName = function (): string {
        return algoliaConfig.productIndexName;
    }

    const getTopSellerIndexName = function (): string {
        return algoliaConfig.topsellerIndexName;
    }

    const initClient = function (sessionContext: SessionContext | undefined): void {
        if (!localStorage.searchHistory) {
            localStorage.searchHistory = JSON.stringify({});
        }

        let config: InitParams = {
            appId: useRuntimeConfig().public.algolia.applicationId,
            apiKey: useRuntimeConfig().public.algolia.apiKey,
            useCookie: true,
            partial: true
        };

        if (sessionContext?.customer?.id) {
            config.authenticatedUserToken = sessionContext.customer.id;
        }

        insightsClient('init', config)
    }

    const addSearchHistory = (productId: string, queryId: string) => {
        if (!productId || !queryId) return;
        let history = JSON.parse(localStorage.searchHistory);
        history[productId] = queryId;
        localStorage.searchHistory = JSON.stringify(history);
    }

    const getSearchHistoryForId = (productId: string) => {
        let history = JSON.parse(localStorage.searchHistory);
        return history[productId] ?? null;
    }

    const getObject = async (indexName: string, objectId: string, transformerFn?: any, tries: number = 0) => {
        try {
            const {data: product} = await useAsyncData(
                'indexName-objectId-' + objectId,
                async () => {
                    const algoliaIndex = useAlgoliaInitIndex(indexName);

                    if (process.server) {
                        const nuxtApp = useNuxtApp();
                        nuxtApp.$algolia.transporter.requester = (await import("@algolia/requester-node-http").then((lib) => lib.default || lib)).createNodeHttpRequester();
                    }

                    const result = algoliaIndex.getObject(objectId)
                        .then((response: any) => {
                            if (transformerFn) {
                                return transformerFn(response);
                            }

                            return response;
                        })
                        .catch((e: any) => {
                            console.error('getObject', {
                                indexName: indexName,
                                objectId: objectId,
                                exception: e,
                            });

                            // throw custom exception
                            throw new Error('Object not found. Going for retry #' + tries + ' (' + indexName + '__' + objectId + ')');
                        });

                    return await result
                }
            );

            return product;
        } catch (e) {
            if (tries < 3) {
                tries++;
                return getObject(indexName, objectId, transformerFn, tries);
            }
            console.error('getObject', {
                indexName: indexName,
                objectId: objectId,
                exception: e,
            });
            return null;
        }
    }

    const getObjects = async (cacheKey: string, indexName: string, objectIds: Array<string>, transformerFn?: any) => {
        const {data} = await useAsyncData(
            cacheKey,
            async () => {
                const algoliaIndex = useAlgoliaInitIndex(indexName);

                if (process.server) {
                    const nuxtApp = useNuxtApp();
                    nuxtApp.$algolia.transporter.requester = (await import("@algolia/requester-node-http").then((lib) => lib.default || lib)).createNodeHttpRequester();
                }

                const result = algoliaIndex.getObjects(objectIds);
                if (transformerFn) {
                    result.then(transformerFn)
                }

                result.catch((e: any) => {
                    console.error('getObjects',  {
                        indexName: indexName,
                        objectIds: objectIds,
                        exception: e,
                    });
                    return null;
                });

                return await result;
            }
        );

        return data;
    }

    return {
        insightsSettings,
        algoliaSetAuthenticatedToken,
        algoliaTrackPurchase,
        algoliaTrackAddToCart,
        algoliaTrackClick,
        algoliaGetObject: getObject,
        algoliaGetObjects: getObjects,
        insightsClient,
        initClient,
        getProductIndexName,
        getTopSellerIndexName,
        getAlgoliaConfig,
        addSearchHistory
    }
}
