export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"robots","content":"index, follow"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"Bevco.dk"},{"name":"twitter:site","content":"Bevco.dk"},{"name":"twitter:card","content":"summary"}],"link":[],"style":[],"script":[{"tagPosition":"head","children":"\n\twindow.dataLayer = window.dataLayer || [];\n\tfunction gtag() {window.dataLayer.push(arguments);}\n\tgtag('js', new Date());\n\tgtag('config', \"G-WWRYXXQ8RY\", {\n\t\t'send_page_view': true,\n\t\t'user_id': ''\n\t});\n\tgtag('get', \"G-WWRYXXQ8RY\", 'client_id', (clientID) => {\n\t\tdocument.cookie = `__ga_client_id=${clientID}; path=/`;\n\t});"},{"src":"https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=MgCWaz","async":true,"defer":true,"type":"text/javascript","tagPosition":"bodyClose"},{"src":"//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js","async":true,"type":"text/javascript","tagPosition":"head"},{"type":"application/ld+json","innerHTML":"{\n\"@context\": \"https://schema.org\",\n\"@type\": \"Organization\",\n\"name\": \"BEVCO\",\n\"alternateName\": \"BEVCO A/S\",\n\"url\": \"https://www.bevco.dk/\",\n\"logo\": \"https://www.bevco.dk/logo.svg\",\n\"address\": {\n   \"@type\": \"PostalAddress\",\n   \"addressLocality\": \"Tilst, Denmark\",\n   \"postalCode\": \"DK-8381\",\n   \"streetAddress\": \"Blomstervej 60\"\n},\n\"contactPoint\": {\n   \"@type\": \"ContactPoint\",\n   \"telephone\": \"+4571992200\",\n   \"contactType\": \"customer service\",\n   \"contactOption\": \"TollFree\",\n   \"areaServed\": \"DK\",\n   \"availableLanguage\": [\"da-DK\",\"en-GB\"]\n},\n\"sameAs\": [\n   \"https://www.facebook.com/Bevco.dk/\",\n   \"https://www.instagram.com/bevcodk/\",\n   \"https://dk.linkedin.com/company/bevco-dk\"\n]\n}"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'