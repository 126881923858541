import { defineStore } from 'pinia';

export const useConsentStore = defineStore('consent', {
    state: () => ({
        hasStatisticConsent: false,
        hasMarketingConsent: false,
        hasFunctionalConsent: false,
    }),

    actions: {
        setStatisticConsent(value: boolean) {
            this.hasStatisticConsent = value;
        },
        setMarketingConsent(value: boolean) {
            this.hasMarketingConsent = value;
        },
        setFunctionalConsent(value: boolean) {
            this.hasFunctionalConsent = value;
        },
    },
});
