import {invokePost, ShopwareApiInstance, invokeGet} from "@shopware-pwa/api-client";
import {BevcoFetchReturn} from "~/types/BevcoFetchReturn";
import {ref} from "vue";

export default async function useBevcoFetch(
    apiInstance: ShopwareApiInstance,
    endpoint: string,
    options: {
        method: string;
        body?: object;
    }
): Promise<Partial<BevcoFetchReturn> | null> {
    const result = ref(<any>null);

    if (options.method === 'POST') {
        const {data} = await invokePost({
            address: endpoint,
            payload: options?.body
        }, apiInstance);
        if (data) {
            result.value = data;
        }
    }

    if (options.method === 'GET') {
        const {data} = await invokeGet({
            address: endpoint
        }, apiInstance);
        if (data) {
            result.value = data;
        }
    }

    if (options.method === 'PATCH') {
        const {data} = await apiInstance.invoke.patch(endpoint, options?.body);
        if (data) {
            result.value = data;
        }
    }

    return result.value;
}
