<script setup lang="ts">
import {getSessionContext} from "@shopware-pwa/api-client";
import {SessionContext} from "@shopware-pwa/types";
import useBevcoFetch from "~/composables/useBevcoFetch";
import useAlgolia from "~/composables/useAlgolia";
import Cookies from "js-cookie";
import {ShippingParcelShop} from "~/types/ShippingParcelShop";

const {refreshUser} = useUser();

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

useHead({
    title: "Bevco",
    meta: [{name: "description", content: "Bevco.dk"}],
    htmlAttrs: {
        lang: "da",
    },
});

const {refreshSessionContext, sessionContext} = useSessionContext();
const {initClient} = useAlgolia();
const {apiInstance} = useShopwareContext();

//giant hack to get tracking cookies to work with nuxt
apiInstance._axiosInstance.defaults.headers.common['sw-klaid'] = useCookie('__kla_id').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-ga'] = useCookie('_ga').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-gaclientid'] = useCookie('__ga_client_id').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-gaWWRYXXQ8RY'] = useCookie('_ga_WWRYXXQ8RY').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-fbp'] = useCookie('_fbp').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-fbc'] = useCookie('_fbc').value ?? '';
apiInstance._axiosInstance.defaults.headers.common['sw-pmtrk'] = useCookie('__pmtrk').value ?? '';

onBeforeMount(async () => {
    if (Cookies.get("__pmtrk") === undefined && sessionContext.value?.token) {
        Cookies.set("__pmtrk", sessionContext.value.token, {
            expires: 365,
            sameSite: "Lax",
            path: "/",
        });
    }

    /*
     * Initialize Algolia Analytics
     */
    initClient(sessionContext.value);

    apiInstance._axiosInstance.interceptors.response.use(function (response) {
        return response;
    });

    // await fetchProductPrices();
});


if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = (urlParams.get('hash'));
    const mode = urlParams.get('mode');
    if (mode) {
        Cookies.set("mode", mode, {
            expires: 365,
            sameSite: "Lax",
            path: "/",
        });
    }

    if (window.location.pathname === '/store-api/bevco/login-by-entity-id' && hash) {
        const loginEndpoint = new URL(apiInstance.config.endpoint + '/store-api/bevco/login-by-entity-id');
        loginEndpoint.searchParams.set('hash', hash);

        useBevcoFetch(
            apiInstance,
            loginEndpoint.href,
            {
                method: 'PATCH'
            }
        ).then(async (response) => {
            const router = useRouter();
            await router.replace('/');
            await refreshUser();
            await refreshSessionContext();
        });
    }
}

const sessionContextData = ref();
sessionContextData.value = await getSessionContext(apiInstance);

useSessionContext(sessionContextData.value as SessionContext);

const {refreshCart} = useCart();
const {isLoggedIn} = useUser();
const {initConsentListener} = useConsent();

useNotifications();
useAddress();

onMounted(() => {
    initConsentListener();
    refreshCart();
});

const isSidebarOpen = ref(false);
provide("isSidebarOpen", isSidebarOpen);

const modalContent = ref<string>("");
const modalProps = ref<object | null | undefined>({});
const modalHandler = {
    open: (component: string, props?: object | null) => {
        modalContent.value = component;
        modalProps.value = props;
    },
    close: () => {
        modalContent.value = "";
        modalProps.value = {};
    },
};

provide("modal", {modalContent, modalProps, ...modalHandler});

const isSideMenuOpened = ref(false);
provide("isSideMenuOpened", isSideMenuOpened);

const menuElementOpen = ref(false);
provide("menuElementOpen", menuElementOpen);

const searchActive = ref(false);
provide("searchActive", searchActive);

const mobileSearchOpen = ref(false);
provide("mobileSearchOpen", mobileSearchOpen);

const selectedParcelShop = ref(<ShippingParcelShop|null>null);
provide("selectedParcelShop", selectedParcelShop);

const displayGross = ref(true);
const productPrices = ref({});

provide("displayGross", displayGross);
provide("productPrices", productPrices);
</script>

<template>
<div>
    <NuxtLayout>
        <LayoutNotifications/>
        <NuxtPage/>
        <SharedModal/>
        <AgeConsentModal/>
    </NuxtLayout>
    <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NLLKJ5D" height="0" width="0"
                style="display:none;visibility:hidden"></iframe>
    </noscript>
    <noscript>
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1505419399770016&ev=PageView&noscript=1">
    </noscript>
</div>
</template>

