<script setup lang="ts">
import {ComputedRef} from "vue";
import {Notification} from "@shopware-pwa/composables-next";

defineEmits<{
    (e: "click:close", id: number): void;
}>();

const props = defineProps<{
    notification: Notification;
}>();

const colorCssMap = {
    info: "blue",
    success: "green",
    warning: "orange",
    danger: "red",
};

const themeTypeColor: ComputedRef<string> = computed(
    () => colorCssMap[props.notification.type] || "blue"
);
</script>
<template>
<!-- don't remove; enforce unocss to include dynamically used classes: class="bg-blue-100 bg-green-100 bg-orange-100 bg-red-100" -->
<div
    :id="`toast-${notification.id}`"
    :data-test-id="`notification-element-${notification.type}`"
    :class="`text-${themeTypeColor}-500 bg-${themeTypeColor}-100 dark:bg-${themeTypeColor}-800 dark:text-${themeTypeColor}-200`"
    class="flex items-center w-full max-w-xs p-4 mb-4 shadow font-sans"
    role="alert"
>
    <div
        data-test-id="notification-element-message"
        class="ml-3 text-sm font-normal"
    >
        {{ notification.message }}
    </div>
    <button
        type="button"
        class="p-2 btn-link ml-auto mr-4 h-fit"
        :data-dismiss-target="`toast-${notification.id}`"
        @click="$emit('click:close', notification.id)"
    >
        <span class="sr-only">Close panel</span>
        <NuxtImg
            loading="lazy"
            src="/close.svg"
            height="10"
            width="10"
            alt="Close"
            class="pointer-events-none"
        />
    </button>
</div>
</template>
