<template>
    <button class="hidden" @click="refresh">refresh</button>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';

const connectRefresh =
    (renderFn, unmountFn) =>
        (widgetParams = {}) => ({
            init() {
                renderFn({ refresh: {} }, true);
            },

            render({ instantSearchInstance }) {
                const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
                renderFn({ refresh }, false);
            },

            dispose() {
                unmountFn();
            },
        });

export default {
    name: 'AisRefresh',
    mixins: [createWidgetMixin({ connector: connectRefresh })],
    methods: {
        refresh() {
            this.state.refresh();
        }
    }
};
</script>
